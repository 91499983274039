<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="nav nav-underline nav-justified">
					  <li class="nav-item">
					    <router-link class="nav-link active" to="/my-bet">My Bet</router-link>
					  </li>
					  <li class="nav-item">
					    <router-link class="nav-link" to="/transactions">Transactions</router-link>
					  </li>
					</ul>
				</div>
				<div class="col-12 mt-3">
					<h1>My Bet</h1>
					<div v-if="Object.keys(results).length > 0">
						<div class="table-responsive">
							<div class="mb-3" v-for="res in results.bets" :key="res.id">
								<div class="card" v-bind:class="{ 'text-bg-success' : (res.status == 2) }">
									<router-link :to="'/bet/' + res.id" class="mybetLink">
										<div class="card-header fw-bold">
											<div class="row">
												<div class="col-8">
													{{ res.bet_type }}
												</div>
												<div class="col text-end">
													₱ {{ res.amount }}
												</div>
											</div>
										</div>
										<div class="card-body">
											<div class="row">
												<div class="col-8">
													<div>Date: {{ res.draw_date }}</div>
													<div>Time: {{ res.draw_time }}</div>
													<div v-if="res.bet_type == 'ez2 jueteng' && res.is_ramble == 1">
														<strong>Rambolito</strong>
													</div>
												</div>
												<div class="col text-center">
													<h3>
														<div v-if="res.status == 2" class="float-end text-warning animate__animated animate__infinite animate__tada">
															<i class="fa-solid fa-trophy"></i>
														</div>
														{{ res.number }}
													</h3>
													Betting Number
												</div>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
						<nav aria-label="Page navigation">
							<Paginate
							  :page-count="results.totalPage"
							  :click-handler="TriggerPaginate"
							  prev-text="Prev"
							  next-text="Next"
							  container-class="pagination"
							  page-class="page-item"
							  page-link-class="page-link"
							  prev-class="page-item"
							  prev-link-class="page-link"
							  next-class="page-item"
							  next-link-class="page-link"
							  >
							</Paginate>
						</nav>
					</div>
					<div v-else>
						No transaction found
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import Paginate from 'vuejs-paginate'
	export default {
		components: {
			Paginate
		},
		data() {
			return {
				results: {}
			}
		},
		mounted() {
			this.init(1);
		},
		methods: {
			init(page) {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'mybet?page=' + page
				}).then(res => {
					if (res !== undefined) {
						me.results = res.data;
					}
				});
			},

			TriggerPaginate(page){
				this.init(page);
			},

			cancel(id) {
				// this.$fire({
				// 	type: "warning",
				// 	title: "Are you sure you want to cance?",
				// 	text: "You can only cancel this bet one hour before the game.",
				// 	showCancelButton: true,
				// 	showConfirmButton: true,
				// 	confirmButtonText: "YES"
				// }).then(res => {
				// 	if(res.value) {
				// 		axios({
				// 			method: 'post',
				// 			withCredentials: false,
				// 			url: this.$api + 'mybet/cancel',
				// 			data: {
				// 				id: id
				// 			}
				// 		}).then(res => {
				// 			if (res !== undefined) {
				// 				if (res.data.success) {
				// 					this.$fire({
				// 						type: "success",
				// 						title: "success",
				// 						text: res.data.message
				// 					})
				// 				} else {
				// 					this.$fire({
				// 						type: "error",
				// 						title: "ERROR",
				// 						text: res.data.message
				// 					})
				// 				}
				// 			}
				// 		});
				// 	}
				// });
			}
		}
	}
</script>