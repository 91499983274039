<template>
  <div class="hello">
    <div class="loginPage">&nbsp;</div>
    <div v-if="isOTP">
      <otpForm :token="otp_token" :key="otp_token"></otpForm>
    </div>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <router-link to="/">
            <img src="@/assets/images/logo.png" class="login-logo">
          </router-link>
        </div>
        <div class="col-12">
          <div class="login-form">
            <input type="text" class="form-control" v-model="user.phone" placeholder="Enter phone number" autocomplete="off">
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="login-form">
            <input type="password" class="form-control" v-model="user.password" placeholder="Enter password">
          </div>
        </div>
        <div class="row mt-4 mb-4 text-center">
          <div class="col-12">
            <router-link to="/forget" class="text-decoration-none">Forget password</router-link>
          </div>
        </div>
        <div class="col-12 login-submit text-center">
          <button class="btn btn-lg btn-primary" v-on:click="login">LOGIN</button>
          <div class="mt-2">
            <router-link to="/register" class="btn btn-lg btn-warning">REGISTER</router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import axios from 'axios';
  import otpForm from '../views/OTP.vue';
  export default {
    components: {
      otpForm
    },
    data() {
      return {
        user: {
          phone: '',
          password: ''
        },
        isOTP: false,
        otp_token: null,
      }
    },
    mounted() {
      this.init();
    },

    methods: {
      init() {
        if (localStorage.getItem("user") != null) {
          this.$router.push({ path: '/games' });
        }
      },

      login() {
        axios({
          method: 'post',
          url: this.$api + 'login',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: this.user
        })
        .then(res => {
          if (res !== undefined) {
            // localStorage.setItem("authToken", res.data.token);
            // if (res.data.role == "user") {
            //   this.$router.push({ path: '/games' });
            // } else {
            //   this.$router.push({ path: '/agent/dashboard' });
            // }
            this.$fire({
              type: 'info',
              title: 'Account verification',
              html: res.data.msg
            });
            this.otp_token = res.data.otp_token;
            this.isOTP = true;
          } else {
            this.$fire({
              type: 'error',
              title: 'Invalid account'
            });
          }
        });
      }
    }
  }
</script>