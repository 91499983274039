<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="schedule mt-4">
					<div v-if="schedule.schedule != null">
						<div v-for="(n, i) in schedule.schedule" :key="i">
							<div>
								<div class="text-center mb-4">
									<small>DRAW DATE</small>
									<h3>{{ i }}</h3>
								</div>
								<div class="betList">
									<div v-for="(e, j) in n" :key="e.id">
										<div class="text-center">
											<div class="fw-bold">Time, {{ make24(j) }}</div>
										</div>
										<div v-for="s in e" class="mt-3">
											<div class="">
												<div class="row">
													<div class="col-5">
														<img :src="type_icon" class="img-fluid">
													</div>
													<div class="col">
														<div class="row">
															<div class="col-8">
																<div class="draw-text">{{ $route.params.type }} OF</div>
															</div>
															<div class="col">
																<img :src="s.source_icon" class="img-fluid draw-img">
															</div>
														</div>
														<div>
															<button
																v-if="s.status == 1"
																type="button"
																class="btn btn-warning btn-placeBet w-100"
																data-bs-toggle="modal"
																v-on:click="bet.schedule_id = s.id"
																data-bs-target="#placeBet">PLACE YOUR BET</button>
																<div v-else>
																	<button
																		class="btn btn-placeBet btn-secondary btn-closed w-100 text-dark"
																		>
																		<div>CLOSED</div>
																		<span v-if="s.status == 2">
																		RESULT: {{ s.draw_result }}
																		</span>
																		<span v-else>CUT-OFF</span>
																	</button>
																</div>
															<i>{{ s.note }}</i>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="alert alert-info">
							No current schedule
						</div>
					</div>

				</div>
			</div>
		</div>


		<!-- place bet -->
		<div class="modal fade" id="placeBet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		      	<strong>PLACE YOUR LUCKY NUMBER</strong>
		        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		      </div>
		      <form v-on:submit="verifyNumber">
			      <div class="modal-body">
			        <div>
			        	<div class="row inputForDraw">
			        		<div class="col">
			        			<div class="text-bg-light text-center rounded-2 border border-secondary-subtle">
			        				<input
			        					ref="input1"
			        					type="number"
			        					class="form-control text-center shadow-none bg-light border border-0"
			        					v-model="bet.num1"
			        					@input="num1(); handleInput(1);"
			        					@focus="setFocusedInput(1)"
			        					required="true"
			        					:placeholder="gameLabel">
			        				<label v-if="isJueteng">From 0 to 31</label>
			        				<label v-else>From 0 to 9</label>
				        		</div>
			        		</div>
			        		<div class="col">
			        			<div class="text-bg-light text-center rounded-2 border border-secondary-subtle">
			        				<input
			        					ref="input2"
			        					type="number"
			        					class="form-control text-center border-0 shadow-none bg-light"
			        					v-model="bet.num2"
			        					@input="num2(); handleInput(2);"
			        					@focus="setFocusedInput(2)"
			        					required="true"
			        					:placeholder="gameLabel">
			        				<label v-if="isJueteng">From 0 to 31</label>
			        				<label v-else>From 0 to 9</label>
				        		</div>
			        		</div>
			        		<div class="col" v-if="isSWertres">
			        			<div class="text-bg-light text-center rounded-2 border border-secondary-subtle">
			        				<input
			        					ref="input3"
			        					type="number"
			        					class="form-control text-center border-0 shadow-none bg-light"
			        					v-model="bet.num3"
			        					@input="num3(); handleInput(3);"
			        					@focus="setFocusedInput(3)"
			        					required="true"
			        					:placeholder="gameLabel">
			        				<label v-if="isJueteng">From 0 to 31</label>
			        				<label v-else>From 0 to 9</label>
				        		</div>
			        		</div>
			        	</div>
			        </div>
			      </div>
			      <div class="modal-footer">
			        <button type="submit" class="btn btn-warning w-100 btn-lg">Verify & Continue</button>
			      </div>
		      </form>
		    </div>
		  </div>
		</div>

		<a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#step2" id="modalTo"></a>
		<div class="modal fade" id="step2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
	        	<strong>PLACE YOUR BETTING AMOUNT</strong>
		        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		      </div>
		      <div class="modal-body">
		        <div>
		        	
		        	<div class="row inputForDraw">
		        		<div class="col-12 text-center">
		        			<ul class="selectedNumber">
		        				<li class="border border-secondary-subtle">
				        			<h1 class="fw-bold bettingNumber">{{ bet.num1 }}</h1>
		        				</li>
		        				<li class="border border-secondary-subtle">
				        			<h1 class="fw-bold bettingNumber">{{ bet.num2 }}</h1>
		        				</li>
		        				<li class="border border-secondary-subtle" v-if="bet.num3 != null">
				        			<h1 class="fw-bold bettingNumber">{{ bet.num3 }}</h1>
		        				</li>
		        			</ul>
		        		</div>
		        		<div class="col-12">
		        			<div class="form-floating">
			        			<input type="number"
			        				class="form-control"
			        				style="font-size:20px"
			        				@input="forceInt"
			        				v-model="bet.amount" step="1">
			        			<label>Other amount</label>
		        			</div>
		        			<div class="mt-2" v-if="isRamble">
								<ul class="rambolito-checkbox">
									<li>
										<input
											type="checkbox"
											v-model="bet.isrambolito"
											:disabled="bet.num1 == bet.num2"
											value="1">
									</li>
									<li>
										<div>Rambolito</div>
									</li>
								</ul>
		        			</div>
		        			<div v-if="bet.amount > 0 && bet.isrambolito == 0" class="text-warning mt-3">
		        				Winning price: {{ (bet.amount * jackpot_winning).toLocaleString() }}
		        			</div>
		        			<div class="text-warning mt-3" v-else>
		        				Winning price: {{ ((bet.amount * jackpot_winning) / 2).toLocaleString() }}
		        			</div>

		        		</div>
		        	</div>
		        </div>
		      </div>
		      <div class="modal-body">
		      	<div class="row amountOption">
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 10">
		      				<div class="card-body text-center">10</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 50">
		      				<div class="card-body text-center">50</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 100">
		      				<div class="card-body text-center">100</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 200">
		      				<div class="card-body text-center">200</div>
		      			</div>
		      		</div>
		      	</div>
		      	<div class="row amountOption mt-2">
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 300">
		      				<div class="card-body text-center">300</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 400">
		      				<div class="card-body text-center">400</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 500">
		      				<div class="card-body text-center">500</div>
		      			</div>
		      		</div>
		      		<div class="col-3 p-1">
		      			<div class="card border" v-on:click="bet.amount = 1000">
		      				<div class="card-body text-center">1000</div>
		      			</div>
		      		</div>
		      	</div>

		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-warning w-100 btn-lg" :disabled="isDisableSubmit" v-on:click="betComplete" data-bs-dismiss="modal" aria-label="Close">PLACE BET</button>
		      </div>
		    </div>
		  </div>
		</div>

	</div>
</template>
<script>
	import axios from 'axios';
	import CountdownTimer from '@/components/CountdownTimer';

	import last2 from '@/assets/images/last2dash.png';
	import swertres from '@/assets/images/suertresdash.png';
	import ez2 from '@/assets/images/ez2dash.png';
	export default {
		components: {
		    CountdownTimer,
		  },
		data() {
			return {
				currentPage: 1,
				numlist: 10,
				type_icon: last2,
				isSWertres: false,
				isJueteng: false,
				isRamble: false,
				gameLabel: "0-9",
				jackpot_winning: 0,
				schedule: {
					info: {
						date: ''
					},
					schedule: null,
				},

				bet: {
					schedule_id: null,
					num1: null,
					num2: null,
					num3: null,
					amount: null,
					isrambolito: 0
				},

				BetShow: null,
				isDisableSubmit: false
			}
		},

		watch: {
			'bet.num1': function(value, i) {
				if (value > 31) {
					this.bet.num1 = 31;
				}
			},
			'bet.num2': function(value, i) {
				if (value > 31) {
					this.bet.num2 = 31;
				}
			},
			'bet.amount': function(value, i) {
				if (value > 50000) {
					this.bet.amount = 50000;
				}
			}
		},

		mounted() {
			this.$refs.input1.focus();
			this.init();
			this.getJackPot(this.$route.params.type);

			if (this.$route.params.type == "swertres") {
				this.isSWertres = true;
				this.type_icon = swertres;
			}
			if (this.$route.params.type == "ez2 jueteng") {
				this.isRamble = true;
				this.isJueteng = true;
				this.gameLabel = "0-31";
				this.type_icon = ez2;
			} 
			
		},

		methods: {
			forceInt() {
				this.bet.amount = Math.round(this.bet.amount);
			},
			selectNumber(number) {
				console.log(this.focusedInput);
		      // Update the value of the focused input
		      if (this.focusedInput === 1) {
		        this.bet.num1 = number;
		      } else if (this.focusedInput === 2) {
		        this.bet.num2 = number;
		      }
		    },
			setFocusedInput(inputNumber) {
		      // Set the focused input
		      this.focusedInput = inputNumber;
		    },
			isJuetng() {
				var lent = 1;
				if (this.isJueteng) {
					lent = 2;
				}
				return lent;
			},
			num1() {
		      this.bet.num1 = this.bet.num1.replace(/\D/g, '');
		      
		      var lent = this.isJuetng();
		      if (this.bet.num1.length > lent) {
		        this.bet.num1 = this.bet.num1.slice(0, 1);
		      }
		    },
		    num2() {
		      this.bet.num2 = this.bet.num2.replace(/\D/g, '');
		      
		      var lent = this.isJuetng();
		      if (this.bet.num2.length > lent) {
		        this.bet.num2 = this.bet.num2.slice(0, 1);
		      }
		    },
		    num3() {
		      this.bet.num3 = this.bet.num3.replace(/\D/g, '');
		      if (this.bet.num3.length > 1) {
		        this.bet.num3 = this.bet.num3.slice(0, 1);
		      }
		    },
			init() {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'schedule?type=' + this.$route.params.type
				}).then(res => {

					if (res !== undefined) {
						me.schedule = res.data;
					}
				});
			},

			getJackPot(str) {
				// get jackpot
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'jackpot?type=' + str
				}).then(res => {

					if (res !== undefined) {
						me.jackpot_winning = res.data.prize;
					}
				});
			},

			betComplete: async function() {
				try {
					if (this.bet.amount != "" || this.bet.amount != null) {
						if (this.bet.amount <= 0) {
							this.$fire({
				              type: 'error',
				              title: 'Amount should be greater than 0'
				            });
						} else {
							this.isDisableSubmit = true;
							axios({
								url: this.$api + 'schedule/number/complete',
								method: 'post',
								withCredentials: false,
								data: this.bet
							}).then(res => {
								if (res !== undefined) {
									if (res.data.success) {
										this.$fire({
							              type: 'success',
							              title: 'Bet successfully submitted',
							              html: 'Place another bet?',
							              showConfirmButton: false,
							              focusConfirm: false,
							              showCancelButton: true,
							              showConfirmButton: true,
							              confirmButtonText: 'YES',
							              cancelButtonText: 'NO',
							              cancelButtonColor: '#d33',
							              // timerProgressBar: true,
							              // timer: 1500
							            }).then((result) => {
							            	var elements = document.getElementsByClassName('btn-close');
									        for (var i = 0; i < elements.length; i++) {
									            elements[i].click();
									        }
							            	if (result.value) {
							            		this.$router.push('/games');
							            	} else {
							            		this.$router.push('/bet/' + res.data.bet_id);
							            	}
							            });

									} else {
										this.$fire({
							              type: 'error',
							              title: res.data.message
							            });
									}
								}
							});
							this.isDisableSubmit = false;
						}

					} else {
						this.$fire({
			              type: 'error',
			              title: 'Amount is required'
			            });
					}
				} catch (err) {
					console.log(err);
					this.$fire({
		              type: 'info',
		              title: 'We are still processing your bet. Please wait'
		            });
				}
			},

			verifyNumber(event) {
				event.preventDefault();
				const me = this;
				axios({
					url: this.$api + 'schedule/number/verify',
					method: 'post',
					withCredentials: false,
					data: this.bet
				}).then(res => {
					if (res !== undefined) {
						if(res.data.isControl) {
							this.$fire({
				              type: 'error',
				              title: 'Sorry this number is already closed for betting'
				            });
						} else {

							if (me.isJueteng) {
								if (me.bet.num1.length == 1) {
									me.bet.num1 = "0" + me.bet.num1;
								}

								if (me.bet.num2.length == 1) {
									me.bet.num2 = "0" + me.bet.num2;
								}
							}
							if (res.data.isAlreadyBet) {
								this.$fire({
									type: "info",
									title: "You already bet this number",
									showConfirmButton: true,
									confirmButtonText: "Yes, Continue",
									showCancelButton: true
								}).then((result) => {
									if (result.value) {
										document.getElementById('modalTo').click();
									}
								});
							} else {
								document.getElementById('modalTo').click();
							}

						}
					}
				});
			},

			make24(timeString) {
			    const [hourString, minute] = timeString.split(":");
			    const hour = +hourString % 24;
			    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
			},

			handleInput(index) {
		      const inputRef = this.$refs['input' + index];

		      var lent = 1;
		      if (this.isJueteng) {
		      	lent = 2;
		      }

		      if (inputRef && inputRef.value.length === lent) {
		        const nextIndex = index + 1;

		        if (this.isSWertres) {
			        if (nextIndex <= 3) {
			          this.$refs['input' + nextIndex].focus();
			        }
		        } else {
		        	if (nextIndex <= 2) {
			          this.$refs['input' + nextIndex].focus();
			        }
		        }
		      }
		  	}
		}	
	}
</script>