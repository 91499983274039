<template>
  <div class="hello loginPage">
    <div v-if="isComplete">
      <div class="container" style="padding-top:100px">
        <div class="alert alert-info">
          <h3>Thank you for registering as agent</h3>
          <p>We'll review your application</p>
        </div>
        
      </div>
    </div>
    <form v-else @submit="register">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center mt-4">
            <h1>Agent Registration</h1>
            <p>You are invited to be an agent</p>
          </div>
          <div class="col-12 mt-2">
            <div class="login-form">
              <input type="text" class="form-control" v-model="user.phone" placeholder="Enter phone number (Required)" autocomplete="off" required="true">
            </div>
          </div>
          <div class="col-12 mt-2 mb-3">
            <div class="login-form">
              <input type="password" class="form-control" v-model="user.password" placeholder="Enter password (Required)" required="true">
            </div>
          </div>

          <div class="col-12">
            <div class="login-form">
              <input type="text" class="form-control" v-model="user.name" placeholder="Enter name (Optional)" autocomplete="off">
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="login-form">
              <input type="text" class="form-control" v-model="user.email" placeholder="Enter email address (Optional)" autocomplete="off">
            </div>
          </div>

          <div class="col-12 login-submit text-center mt-3">
            <button class="btn btn-lg btn-primary">REGISTER</button>
            <div class="mt-2">
              <router-link to="/" class="btn btn-lg btn-warning">LOG IN</router-link>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        isComplete: false,
        user: {
          name: '',
          types: this.$route.params.types,
          agent: this.$route.params.agent,
          email: '',
          phone: '',
          password: ''
        },

        hasAgent: false,
      }
    },
    mounted() {
      this.init();
    },

    methods: {
      init() {
        axios({
          method: 'get',
          url: this.$api + 'agent/checker/' + this.$route.params.agent,
          withCredentials: false
        })
        .then(res => {
          if (res === undefined || !res.data.is_valid) {
            this.$fire({
              type: 'error',
              title: 'This is invalid invitation'
            });;
          }
        })
      },

      register(event) {
        event.preventDefault();
        axios({
          method: 'post',
          url: this.$api + 'register/agent',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: this.user
        })
        .then(res => {
          if (res !== undefined) {
            this.isComplete = true;
          }
        })
        .catch(error => {
          let err = Object.values(error.response.data.errors);
          let htmlerr = "";
          err.forEach((err) => {
            console.log(err);
            htmlerr = htmlerr + "<div>"+ err +"</div>";
          });

          this.$fire({
            type: 'error',
            title: error.response.data.message,
            html: htmlerr,
            timerProgressBar: true
          });
        });
      }
    }
  }
</script>