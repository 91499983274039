<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h3>Cash out</h3>
					</div>
					<div v-if="!(isCashout.gcash == false && isCashout.paymaya == false)">
						<form v-on:submit="cashout">
							<div class="card-body">
								<div class="form-floating mb-3">
									<input class="form-control" required="true" v-model="cashOut.amount" />
									<label>Amount</label>
								</div>
								<div class="form-floating">
									<select class="form-select" v-model="cashOut.gateway">
										<option value="paymaya" v-if="isCashout.paymaya">PAYMAYA</option>
										<option v-else disabled>PAYMAYA (Maintenance)</option>

										<option value="gcash" v-if="isCashout.gcash">GCASH</option>
										<option v-else disabled>GCASH (Maintenance)</option>
									</select>
									<label>E-wallet</label>
								</div>
								<div class="form-floating mt-3">
									<input class="form-control" required="true" v-model="cashOut.account_number" />
									<label>Account number</label>
								</div>
								<div class="form-floating mt-3">
									<input class="form-control" required="true" v-model="cashOut.account_name" />
									<label>Account name</label>
								</div>
							</div>
							<div class="card-footer">
								<h3 class="mb-4">Transaction breakdown</h3>
								<div class="row">
									<div class="col-12">
										<strong>Transaction fee</strong>
									</div>
									<div class="col-6">
										<div class="text-success">
											<i class="fa fa-peso-sign"></i> {{ (((cashOut.amount * 2) / 100) + 10).toLocaleString() }}
										</div>
									</div>
									<div class="col-6 text-muted text-end">2% + ₱10</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 text-primary">
										<strong>Net amount</strong>
										<div><i class="fa fa-peso-sign"></i> {{ (cashOut.amount - (((cashOut.amount * 2) / 100) + 10)).toLocaleString() }}</div>
									</div>
								</div>
								<button type="submit" class="btn btn-lg btn-primary mt-4 w-100 rounded-pill">CASH OUT</button>
							</div>
						</form>
					</div>
					<div class="card-body" v-else>
						<div class="alert alert-info">
							<p>Our cashout feature is undergoing maintenance. We apologize for any inconvenience.</p>
						</div>
					</div>
				</div>
				<div class="card mt-4">
					<div class="card-header">
						<h3>Cash out history</h3>
					</div>
					<div class="card-body">
						<div v-for="r in req.data" :key="r.id" class="mb-3">
							<div class="card">
								<div class="card-header">
									<div class="row">
										<div class="col-4">{{ r.gateway }}</div>
										<div class="col text-end">
											<span v-if="r.status == 1" class="text-success">Success</span>
											<span v-else-if="r.status == 3" class="text-danger">Failed</span>
											<span v-else class="text-warning">Proccessing</span>
										</div>
									</div>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-6">
											<div>Account #: {{ r.account_number }}</div>
											<div>Name: {{ r.account_name }}</div>
										</div>
										<div class="col text-end">
											<h3>{{ r.amount }} <i class="fa fa-peso-sign"></i></h3>
										</div>
									</div>
								</div>
								<div class="card-footer">
									{{ r.created_at }}
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<nav aria-label="Page navigation">
							<Paginate
							  :page-count="req.totalPage"
							  :click-handler="TriggerPaginate"
							  prev-text="Prev"
							  next-text="Next"
							  container-class="pagination"
							  page-class="page-item"
							  page-link-class="page-link"
							  prev-class="page-item"
							  prev-link-class="page-link"
							  next-class="page-item"
							  next-link-class="page-link"
							  >
							</Paginate>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import Paginate from 'vuejs-paginate'
	export default {
		components: {
			Paginate
		},
		data() {
			return {
				isCashout: {
					gcash: 1,
					paymaya: 1
				},
				cashOut: {
					gateway: 'paymaya',
					amount: 112,
					account_number: '',
					account_name: '',
				},
				req: [{
					'id': '',
					'account_name': '',
					'account_number': '',
					'amount': '',
					'created_at': '',
					'gateway': '',
					'status': '',
				}]
			}
		},
		mounted() {
			this.init(1);
			this.checkCashout();
		},

		methods: {
			TriggerPaginate(page){
				this.init(page);
			},

			checkCashout() {
				axios({
		          method: 'get',
		          url: this.$api + 'gateway/cashout',
		          withCredentials: false,
		          headers: {
		              'Content-Type': 'application/json'
		          }
		        })
		        .then(res => {
		          this.isCashout = res.data.cashout;
		        });
			},
			init(page) {
				axios({
		          method: 'get',
		          url: this.$api + 'account/withdraw?page=' + page,
		          withCredentials: false,
		          headers: {
		              'Content-Type': 'application/json'
		          }
		        })
		        .then(res => {
		          this.req = res.data;
		        });
			},

			cashout(e) {
				e.preventDefault();
				axios({
		          method: 'post',
		          url: this.$api + 'gateway/withdraw',
		          withCredentials: false,
		          headers: {
		              'Content-Type': 'application/json'
		          },
		          data: this.cashOut
		        })
		        .then(res => {
		          if (res.data.success) {
		            this.$fire({
		              type: 'success',
		              title: 'Congratulation',
		              text: res.data.message
		            });
		          } else {
		            this.$fire({
		              type: 'error',
		              title: 'Warning',
		              text: res.data.message
		            });
		          }
		        }).catch(error => {
					let err = Object.values(error.response.data.errors);
					let htmlerr = "";
					err.forEach((err) => {
						htmlerr = htmlerr + "<div>"+ err +"</div>";
					});

					this.$fire({
		              type: 'error',
		              title: error.response.data.message,
		              html: htmlerr,
		              timerProgressBar: true,
		              timer: 2000
		            });
				});
			}
		}
	}
</script>