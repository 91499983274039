<template>
	<div>
		<div class="card">
			<div class="card-body">
				<span>Invite bettors</span>
				<div class="input-group flex-nowrap">
				  <input type="text" class="form-control" id="clipURL" :value="bettorsURL">
				  <button class="input-group-text" @click="copyToClipboard">
				  	<i class="fa-solid fa-copy"></i>
				  </button>
				</div>
			</div>
			<div class="card-body" v-if="agent_type == 'master'">
				<span>Invite sub agent</span>
				<div class="input-group flex-nowrap">
				  <input type="text" class="form-control" id="sub" :value="subAgentUrl">
				  <button class="input-group-text" @click="copyToClipboardSubAgent">
				  	<i class="fa-solid fa-copy"></i>
				  </button>
				</div>
			</div>
			<div class="card-body" v-if="agent_type == 'master' || agent_type == 'sub'">
				<span>Invite agent</span>
				<div class="input-group flex-nowrap">
				  <input type="text" class="form-control" id="agent" :value="agentURL">
				  <button class="input-group-text" @click="copyToClipboardAgent">
				  	<i class="fa-solid fa-copy"></i>
				  </button>
				</div>
			</div>

		</div>
		<div class="mt-3" v-if="isMasterAgent">
			<div class="border border-1 p-2">
				<h3>Agent requesting for approval</h3>
				<div class="">
					<div v-if="requestingAgent.length !== 0">
						<div v-for="rec in requestingAgent" :key="rec.id">
							<div class="card mt-3 mb-3">
								<div class="card-header">
									<div class="row">
										<div class="col-6">
											<strong>{{ rec.phone }}</strong>
										</div>
										<div class="col text-end">
											<button v-on:click="accept(rec.id)" class="btn btn-sm btn-success me-1">Accept</button>
											<button class="btn btn-sm btn-danger">Reject</button>
										</div>
									</div>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col">
											<h3>{{ rec.username }} ({{ rec.type }})</h3>
											<small>{{ rec.request_date }}</small>
										</div>
										<div class="col">
											<ul class="text-start clist">
												<li v-for="(i, n) in JSON.parse(rec.commissions)">{{ n }} : {{ i }}%</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" v-else>
						<div>No approval request</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				isMasterAgent: false,
				agent_type: "",
				bettorsURL: '',
				subAgentUrl: '',
				agentURL: '',
				requestingAgent: {}
			}
		},
		mounted() {
			this.init();
		},

		methods: {
			init() {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'agent'
				}).then(res => {
					if (res !== undefined) {
						if (res.data.info.type === "master" || res.data.info.type == "sub") {
							me.isMasterAgent = true;
						}
						me.agent_type = res.data.info.type;
						me.bettorsURL = this.$ui + 'register?agent=' +res.data.info.username;
						me.subAgentUrl = this.$ui + 'reg/subagent/' + res.data.info.username + '/invites';
						me.agentURL = this.$ui + 'reg/agent/' + res.data.info.username + '/invites';
						me.requestingAgent = res.data.agent_request;
					}
				});
			},
		    copyToClipboard() {
		      const copyText = document.getElementById("clipURL");
		      copyText.select();
		      copyText.setSelectionRange(0, 99999);
		      navigator.clipboard.writeText(copyText.value);
		    },

		    copyToClipboardSubAgent() {
		      const copyText = document.getElementById("sub");
		      copyText.select();
		      copyText.setSelectionRange(0, 99999);
		      navigator.clipboard.writeText(copyText.value);
		    },

		    copyToClipboardAgent() {
		      const copyText = document.getElementById("agent");
		      copyText.select();
		      copyText.setSelectionRange(0, 99999);
		      navigator.clipboard.writeText(copyText.value);
		    },

		    accept(id) {
		    	let me = this;
		    	this.$fire({
				  title: "Accepting agent",
				  showDenyButton: true,
				  showCancelButton: true,
				  confirmButtonText: "Accept",
				  denyButtonText: "Cancel"
				}).then((result) => {
					if (result.value) {
						axios({
							method: 'post',
							withCredentials: false,
							url: this.$api + 'agent/approve',
							data: {
								user_id: id
							},
						}).then(res => {
							if (res !== undefined) {
								this.$fire({
						            type: 'success',
						            title: res.data.message,
						            timerProgressBar: true
						          });
								me.init();
							}
						})
						.catch(error => {
				          let err = Object.values(error.response.data.errors);
				          let htmlerr = "";
				          err.forEach((err) => {
				            console.log(err);
				            htmlerr = htmlerr + "<div>"+ err +"</div>";
				          });

				          this.$fire({
				            type: 'error',
				            title: error.response.data.message,
				            html: htmlerr,
				            timerProgressBar: true
				          });
				        });;
					}
				});
		    }
		}
	}
</script>