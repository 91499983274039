<template>
	<div>
		<div class="container">
			<div class="row" v-if="hasVideo">
				<div class="col-12">
					<div>
						<h3 class="text-center">{{ video.title }}</h3>
						<iframe
							class="rounded-3"
							width="100%"
							height="300"
							:src="'https://www.youtube.com/embed/' +video.video + '?rel=0'"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen></iframe>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center mt-3">
					<h2>Choose your game</h2>
				</div>
				<div class="col-12">
					<router-link to="/game/last two" class="text-decoration-none">
						<div class="game-list card text-bg-danger mb-3 radius-top-right">
							<div class="card-body">
								<div class="row">
									<div class="col-6">
										<img class="img-fluid rounded-start-4" alt="last 2" src="@/assets/images/win750.png"/>
									</div>
									<div class="col text-end">
										<img class="game1 img-fluid" alt="last 2" src="@/assets/images/last2dash.png"/>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
				<div class="col-12">
					<router-link to="/game/ez2 jueteng" class="text-decoration-none">
						<div class="game-list card text-bg-warning mb-3">
							<div class="card-body">
								<div class="row">
									<div class="col">
										<img class="game1 img-fluid" alt="ez 2" src="@/assets/images/ez2dash.png"/>
									</div>
									<div class="col-6 text-end">
										<img class="img-fluid rounded-end-4" alt="last 2" src="@/assets/images/win5000.png"/>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
				<div class="col-12">
					<router-link to="/game/swertres">
						<div class="game-list card text-bg-secondary mb-3 radius-bottom-left">
							<div class="card-body">
								<div class="row">
									<div class="col-6">
										<img class="img-fluid rounded-start-4" alt="last 2" src="@/assets/images/win5500.png"/>
									</div>
									<div class="col text-end">
										<img class="game1 img-fluid" alt="last 2" src="@/assets/images/suertresdash.png"/>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				hasVideo: false,
				video: {
					title: '',
					video: {},
				}
			}
		},
		mounted() {
			this.init();
		},

		methods: {

			init() {
				axios({
		          method: 'get',
		          url: this.$api + 'live',
		          withCredentials: false,
		          headers: {
		              'Content-Type': 'application/json'
		          }
		        })
		        .then(res => {
		          if(res.data.success) {
		          	 if (res.data.video !== null) {
		          	  this.hasVideo = true;
			          this.video = res.data.video;
		          	 }
		          }
		        });
			},
		}
	}
</script>