<template>
	<div>
		<div class="">
			<h3>Commissions</h3>
			<div v-if="commission.length !== 0">
				<div class="card mb-3" v-for="c in commission" :key="c.id">
					<div class="card-header">
						<div class="row">
							<div class="col">
								From: {{ c.phone }}
							</div>
							<div class="col text-end">
								{{ c.created_at }}
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-8">
								<h3 class="text-success">+{{ c.amount }}php</h3>
							</div>
							<div class="col">
								<span class="text-muted">{{ c.commission_percent }}% commission</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="text-center">No commission found</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				commission: {}
			}
		},
		mounted() {
			const me = this;
			axios({
				method: 'get',
				withCredentials: false,
				url: this.$api + 'agent/commissions'
			}).then(res => {
				if (res !== undefined) {
					this.commission = res.data;
				}
			});
		}
	}
</script>