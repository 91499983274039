/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueSimpleAlert from "vue-simple-alert";

import "@/assets/js/bootstrap.min.js"

import "@/assets/css/dark.css"
import "@/assets/css/animate.min.css"
import "@/assets/css/bootstrap.min.css"
import "@/assets/css/all.min.css"
import "@/assets/css/app.css"
import "@/assets/css/responsive.css"

import axios from 'axios';

Vue.config.productionTip = false;
Vue.prototype.$api = 'https://api.pisolottery.online/api/';
Vue.prototype.$ui = 'https://www.pisolottery.online/';
// Vue.prototype.$api = 'http://127.0.0.1:8000/api/';
// Vue.prototype.$ui = 'http://localhost:8080/';

Vue.prototype.$isAuth = false;


axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem('authToken'); // Retrieve the authorization token from storage

  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Set the authorization header
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});


axios.interceptors.request.use(function (config) {
  document.getElementById('reqLoading').style.display = 'block';
  return config;
});

axios.interceptors.response.use(
  response => {
    // Pass through successful responses
    document.getElementById('reqLoading').style.display = 'none';
    return response;
  },
  error => {
    document.getElementById('reqLoading').style.display = 'none';
    if (error.response && error.response.status === 401) {
      return Promise.resolve();
    } else if (error.response.status === 403) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueSimpleAlert);