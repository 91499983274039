<template>
  <div class="hello">
    <form @submit="verifyOTP">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <router-link to="/">
              <img src="@/assets/images/logo.png" class="login-logo">
            </router-link>
          </div>
          <div class="col-12 mt-2">
            <div class="login-form">
              <input type="text" class="form-control w-100" v-model="otp.code" placeholder="OTP Code" autocomplete="off" required="true">
            </div>
            <div class="col-12 login-submit text-center mt-3">
              <button class="btn btn-lg btn-primary w-100">Verify</button>

              <button type="button" class="btn btn-link text-decoration-none mt-3" v-on:click="resendOTP" :disabled="!isResend">
                Resend OTP <span v-if="!isResend">({{ reSendCounter }})</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    props: {
      token: String
    },
    data() {
      return {
        otp: {
          code: '',
          otp_token: '',
        },

        reSendCounter: 0,
        isResend: false,

        hasAgent: false,
        intervalId: null
      }
    },

    mounted() {
      this.otp.otp_token = this.token;
      this.OTPCounter();
    },
    methods: {
      resendOTP() {
        var me = this;
        this.isResend = false;
        me.OTPCounter();
        axios({
          method: 'post',
          url: this.$api + 'otp/resend',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: {
            "otp_token" : this.otp.otp_token
          }
        })
        .then(res => {
          if (res.data.success) {
            this.$fire({
              type: 'success',
              title: res.data.message
            });
            me.OTPCounter();
          }
        });
      },

      OTPCounter() {
        var me = this;
        this.reSendCounter = 120;
        if (this.intervalId === null) {
          // if (this.otp.otp_token != null) {
            if (!this.isResend) {
              this.intervalId = setInterval(() => {
                me.reSendCounter--;
                if (me.reSendCounter == 0) {
                  me.isResend = true;
                  clearInterval(me.intervalId);
                  me.intervalId = null;
                }
              }, 1000);
            }
          // }
        }

      },

      verifyOTP(event) {
        event.preventDefault();
        axios({
          method: 'post',
          url: this.$api + 'otp',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: this.otp
        })
        .then(res => {
          if (res !== undefined) {
            if (!res.data.status) {
              this.$fire({
                type: 'error',
                title: res.data.msg
              });
            } else {
              localStorage.setItem("authToken", res.data.token);
              if (res.data.role == "user") {
                this.$router.push({ path: '/games' });
              } else {
                this.$router.push({ path: '/agent/dashboard' });
              }
            }

          } else {
            this.$fire({
              type: 'error',
              title: 'Invalid account'
            });
          }
        })
        .catch(error => {
          let err = Object.values(error.response.data.errors);
          let htmlerr = "";
          err.forEach((err) => {
            console.log(err);
            htmlerr = htmlerr + "<div>"+ err +"</div>";
          });

          this.$fire({
            type: 'error',
            title: error.response.data.message,
            html: htmlerr,
            timerProgressBar: true
          });
        });
      }
    }
  }
</script>