<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1>Lotto Results</h1>
					<div v-if="Object.keys(results.result).length > 0">
						<div v-for="(res, i) in results.result" :key="i" class="mt-3">
							<div class="border border-0 p-2 mb-3">
								<h5 class="text-center text-primary">{{ i }}</h5>
								<div class="">
									<div v-for="rdata, time in res" :key="time">
										<div class="table-responsive">
											<table class="table table-striped text-center caption-top">
												<caption class="">{{ time }} Draw</caption>
												<thead>
													<tr>
														<th>Game</th>
														<th>Draw in</th>
														<th>Result</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="r in rdata" :key="r.id">
														<td>{{ r.bet_type }}</td>
														<td class="text-left">
															<img :src="r.source_icon" class="" width="35">
														</td>
														<td width="45%">
															<div v-if="r.draw_result.length < 2">-</div>
															<div v-else>
																<ul class="draw-result">
																	<li v-for="i in r.draw_result" :key="i">
																		<div>{{ i }}</div>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<nav aria-label="Page navigation">
							<Paginate
							  :page-count="results.totalPage"
							  :click-handler="TriggerPaginate"
							  prev-text="Prev"
							  next-text="Next"
							  container-class="pagination"
							  page-class="page-item"
							  page-link-class="page-link"
							  prev-class="page-item"
							  prev-link-class="page-link"
							  next-class="page-item"
							  next-link-class="page-link"
							  >
							</Paginate>
						</nav>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import Paginate from 'vuejs-paginate'
	export default {
		components: {
			Paginate
		},
		data() {
			return {
				results: {}
			}
		},
		mounted() {
			this.init(1);
		},
		methods: {
			init(page) {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'schedule/results?page=' + page
				}).then(res => {
					if (res !== undefined) {
						me.results = res.data;
					}
				});
			},

			TriggerPaginate(page){
				this.init(page);
			},
		}
	}
</script>