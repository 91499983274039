<template>
	<div>
		<div class="p-3 mt-3">
			<div class="">
				<h3>Agents</h3>
				<div class="">
					<div v-if="subAgents.length !== 0">
						<div v-for="rec in subAgents" :key="rec.id">
							<div class="card mb-3">
								<div class="card-header">
									<div class="row">
										<div class="col-6">
											<router-link :to="'/agent/subagent/profile/' + rec.id">
												{{ rec.username }}
											</router-link>
										</div>
										<div class="col text-end">
											<button
												v-on:click="selectedAgent = rec.id; extractCommision(rec.commissions)"
												data-bs-toggle="modal"
												data-bs-target="#addComModals"
												class="btn btn-sm btn-warning me-1">Commission</button>
										</div>
									</div>
								</div>
								<div class="card-body">
									<ul class="text-start clist">
										<li v-for="(i, n) in JSON.parse(rec.commissions)">{{ n }} : {{ i }}%</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" v-else>
						No agent found
					</div>
				</div>
			</div>
		</div>
		
		<!-- Modal -->
		<div class="modal fade" id="addComModals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h1 class="modal-title fs-5" id="exampleModalLabel">Update commission</h1>
		        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		      </div>
		      <div class="modal-body">
		        <form @submit="addCommission">
		        	<div class="form-floating mb-2">
		        		<input type="number" v-model="commissionList.LAST2" class="form-control">
		        		<label>LAST TWO</label>
		        	</div>
		        	<div class="form-floating mb-2">
		        		<input type="number" v-model="commissionList.SWERTRES" class="form-control">
		        		<label>SWERTRES</label>
		        	</div>
		        	<div class="form-floating mb-2">
		        		<input type="number" v-model="commissionList.JUETENG" class="form-control">
		        		<label>JUETENG</label>
		        	</div>
		        	<button type="submit" class="btn btn-primary w-100 mt-2">Update agent commission</button>
		        </form>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				subAgents: {},
				selectedAgent: 0,
				commission: 0,
				myCommission: 0,
				commissionList: {
					'LAST2': 0,
					'SWERTRES': 0,
					'JUETENG': 0,
				}
			}
		},
		mounted() {
			this.init();
		},

		methods: {
			extractCommision(com) {
				let coms = JSON.parse(com);
				this.commissionList.LAST2 = coms.LAST2;
				this.commissionList.SWERTRES = coms.SWERTRES;
				this.commissionList.JUETENG = coms.JUETENG;
			},

			init() {
				axios({
					method: 'get',
					url: this.$api + 'agent/agents',
					withCredentials: false,
				}).then(res => {
					if (res !== undefined) {
						this.subAgents = res.data.agents;
						this.myCommission = res.data.commission;
					}
				});
			},
			addCommission(e) {
				e.preventDefault();
		    	axios({
					method: 'post',
					withCredentials: false,
					url: this.$api + 'agent/subcommission/update',
					data: {
						'agent_id': this.selectedAgent,
						'commission': this.commissionList,
					}
				}).then(res => {
					if (res !== undefined) {
						document.getElementById("close").click();
						this.$fire({
				            type: 'success',
				            title: res.data.message,
				            timerProgressBar: true
				          });
						this.init();
					}
				})
				.catch(error => {
		          let err = Object.values(error.response.data.errors);
		          let htmlerr = "";
		          err.forEach((err) => {
		            console.log(err);
		            htmlerr = htmlerr + "<div>"+ err +"</div>";
		          });

		          this.$fire({
		            type: 'error',
		            title: error.response.data.message,
		            html: htmlerr,
		            timerProgressBar: true
		          });
		        });
		    }
		}
	}
</script>