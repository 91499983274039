<template>
  <div>
    <div v-for="(time, objects) in timeData" :key="time.id">
      <div class="countdown">
        <span>{{ formatTime(time) }}</span>
      </div>
      <div v-for="(object, index) in objects" :key="index">
        <!-- Display other object data here -->
      </div>
    </div>
  </div>
</template>

<script>
const INTERVAL = 1000; // 1 second interval

export default {
  props: {
    timeData: Object, // Pass the object containing time values and associated data
  },
  data() {
    return {
      currentTime: new Date(),
    };
  },
  computed: {
  formatTime() {
    return (timeData) => {
      const targetTime = timeData[0].draw_time; // Assuming the time is stored in 'draw_time' property
      const currentTime = new Date();
      const targetParts = targetTime.split(':');
      const targetDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), targetParts[0], targetParts[1], targetParts[2]);

      const timeDifference = targetDate - currentTime;
      if (timeDifference < 0) {
        return 'Time expired';
      }

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
  },
},

  created() {
    // Refresh the countdown every second
    this.timer = setInterval(this.updateCurrentTime, INTERVAL);
  },
  beforeDestroy() {
    clearInterval(this.timer); // Clear the interval when the component is destroyed
  },
};
</script>