<template>
	<div>
		<div class="border border-1 p-3 mt-3">
			<div class="">
				<div class="text-center">
					<h3><i class="fa-regular fa-user"></i> {{ agent.info.username }}</h3>
					<div class="mb-4"><i class="fa-solid fa-mobile-retro"></i> {{ agent.phone }}</div>
					<div class="table-responsive">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Number of agents</th>
									<th>Number of Bettors</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{ agent.agent }}</td>
									<td>{{ agent.bettors }}</td>
								</tr>
								<tr>
									<th>
										Commission earned
									</th>
									<th>Agent commission</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="">
							<div class="">
								<strong>Commissions</strong>
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<td>Date</td>
												<td>Total</td>
											</tr>
										</thead>
										<tbody>
											<tr v-for="ag,i in agent.commissions" :key="i">
												<td>{{ i }}</td>
												<td class="text-success">+{{ ag.total }}Php</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				agent: {
					info: {
						username: '',
						commissions: 0,
						total_commission: 0,
					},
					subagent: 0,
					bettors: 0,
					commissions: {}
				},
			}
		},
		mounted() {
			this.init();
		},

		methods: {
			init() {
				axios({
					method: 'get',
					url: this.$api + 'agent/subagents/' + this.$route.params.subagent_id,
					withCredentials: false,
				}).then(res => {
					if (res !== undefined) {
						console.log(res.data);
						this.agent = res.data;
					}
				});
			},
		
		}
	}
</script>