<template>
  <div class="hello loginPage">
    <div v-if="isOTP">
      <otpForm :token="otp_token" :key="otp_token"></otpForm>
    </div>
    <div v-else>
      <form @submit="register">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <router-link to="/">
                <img src="@/assets/images/logo.png" class="login-logo">
              </router-link>
            </div>
            <div class="col-12 mt-2">
              <div class="login-form">
                <input type="text" class="form-control" v-model="user.phone" placeholder="Enter phone number (Required)" autocomplete="off" required="true">
              </div>
            </div>
            <div class="col-12 mt-2 mb-3">
              <div class="login-form">
                <input type="password" class="form-control" v-model="user.password" placeholder="Enter password (Required)" required="true">
              </div>
            </div>

            <div class="col-12">
              <div class="login-form">
                <input type="text" class="form-control" v-model="user.name" placeholder="Enter name (Optional)" autocomplete="off">
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="login-form">
                <input type="text" class="form-control" v-model="user.email" placeholder="Enter email address (Optional)" autocomplete="off">
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="login-form">
                <input type="text" class="form-control" v-model="user.agent" placeholder="Agent username (Optional)" autocomplete="off" v-bind:readonly="isReadOnly">
              </div>
            </div>
            <div class="col-12 login-submit text-center mt-3">
              
              <div class="mb-3">
                <input type="checkbox" class="form-check-input me-3" v-model="user.terms_and_conditions" value="true" required="true">
                <label class="form-check-label">I agree to the <router-link to="/terms">Terms and Conditions</router-link></label>
              </div>

              <button class="btn btn-lg btn-primary">REGISTER</button>
              <div class="mt-2">
                <router-link to="/" class="btn btn-lg btn-warning">LOG IN</router-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>
<script>
  import axios from 'axios';
  import otpForm from '../views/OTP.vue'
  export default {
    components: {
      otpForm
    },
    data() {
      return {
        user: {
          name: '',
          agent: '',
          email: '',
          phone: '',
          password: '',
          terms_and_conditions: false
        },

        isReadOnly: false,
        isOTP: false,
        otpToken: null,
        hasAgent: false,
      }
    },
    mounted() {
      this.init();
      if (this.$route.query.agent !== undefined) {
        this.hasAgent = true;
        this.isReadOnly = true;
        this.user.agent = this.$route.query.agent;
      }

    },

    methods: {
      init() {
        if (localStorage.getItem("user") != null) {
          this.$router.push('/games');
        }
      },

      register(event) {
        event.preventDefault();
        axios({
          method: 'post',
          url: this.$api + 'register',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: this.user
        })
        .then(res => {
          if (res !== undefined) {
            this.$fire({
              type: 'info',
              title: 'Account verification',
              html: res.data.msg
            });

            this.otp_token = res.data.otp_token;
            this.isOTP = true;

          } else {
            this.$fire({
              type: 'error',
              title: 'Invalid account'
            });
          }
        })
        .catch(error => {
          let err = Object.values(error.response.data.errors);
          let htmlerr = "";
          err.forEach((err) => {
            console.log(err);
            htmlerr = htmlerr + "<div>"+ err +"</div>";
          });

          this.$fire({
            type: 'error',
            title: error.response.data.message,
            html: htmlerr,
            timerProgressBar: true
          });
        });
      }
    }
  }
</script>