<template>
	<div>
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		mounted() {
			var user = JSON.parse(localStorage.getItem("user"));
			if (user.role == "user") {
				this.$router.push({ path: '/dashboard' });
			}
		}
	}
</script>