<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1>Profile</h1>
					<form>
						<div class="form-floating mb-1">
							<input type="text" class="form-control" v-model="user.name">
							<label>Name</label>
						</div>
						<div class="form-floating mb-1">
							<input type="text" class="form-control" v-model="user.email">
							<label>Email</label>
						</div>
						<div class="form-floating mb-1">
							<input type="text" class="form-control" v-model="user.phone">
							<label>Phone</label>
						</div>
						<div class="form-floating mb-1">
							<input type="password" class="form-control" v-model="user.password">
							<label>Password</label>
						</div>
						<div class="mt-3">
							<button type="button" class="btn btn-primary w-100" v-on:click="updateProfile">Update</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user"))
			}
		},
		methods: {
			updateProfile() {
				const me = this;
				axios({
					method: 'post',
					withCredentials: false,
					url: this.$api + 'account',
					data: this.user
				}).then(res => {
					if (res !== undefined) {
						localStorage.setItem("user", JSON.stringify(res.data.data));
						this.$fire({
			              type: 'success',
			              title: 'Updated successfully',
			              timerProgressBar: true,
			              timer: 1500
			            });
					}
				}).catch(error => {
					let err = Object.values(error.response.data.errors);
					let htmlerr = "";
					err.forEach((err) => {
						console.log(err);
						htmlerr = htmlerr + "<div>"+ err +"</div>";
					});

					this.$fire({
		              type: 'error',
		              title: error.response.data.message,
		              html: htmlerr,
		              timerProgressBar: true,
		              timer: 2000
		            });
				});
			}
		}
	}
</script>