<template>
	<div class="container">
		<div class="row print">
			<div class="col-12">
				<h3>Betting ID #{{ bet.id }}</h3>
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-6">
								<h3>{{ bet.bet_type }}</h3>
							</div>
							<div class="col text-end">
								<img :src="bet.source_icon" style="width: 30px;" />
								
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col text-center">
								<h3 v-if="bet.bet_status == 2">
									{{ bet.number }} <i class="fa-solid fa-trophy text-warning"></i>
								</h3>
								<h3 v-else>{{ bet.number }}</h3>
								<small>Betting Number</small>
							</div>
							<div class="col text-end">
								<h3><i class="fa fa-peso-sign"></i> {{ bet.amount }}</h3>
								<p>{{ bet.draw_date }}</p>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<small>{{ bet.created_at }}</small>
						<button onclick="window.print()" class="btn btn-sm btn-light float-end noprint">
							<i class="fa fa-print"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				bet: {}
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			init() {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'bet/' + this.$route.params.bet_id
				}).then(res => {
					if (res !== undefined) {
						me.bet = res.data;
					}
				});
			}
		}
	}
</script>