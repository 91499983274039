<template>
  <div class="hello">
    <form @submit="sendForget">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <router-link to="/">
              <img src="@/assets/images/logo.png" class="login-logo">
            </router-link>
            <h3>Forget password</h3>
          </div>
          <div class="col-12 mt-2">
            <div class="login-form">
              <input type="text" class="form-control w-100" v-model="user.phone" placeholder="Phone number" autocomplete="off" required="true">

              <div v-if="validAccount">
                <div class="form-group mt-2">
                   <input type="number" class="form-control w-100" v-model="user.code" placeholder="OTP code">
                </div>

                <div class="form-group mt-2">
                   <input type="password" class="form-control w-100" v-model="user.password" placeholder="New password">
                </div>
              </div>

            </div>
            <div class="col-12 login-submit text-center mt-3">
              <button class="btn btn-lg btn-primary w-100">Forget password</button>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    props: {
      token: String
    },
    data() {
      return {
        user: {
          phone: '',
          code: '',
          otp_token: '',
          password: ''
        },

        validAccount: false
      }
    },

    mounted() {
      this.otp.otp_token = this.token;
      this.OTPCounter();
    },
    methods: {
      sendForget(event) {
        event.preventDefault();
        if (this.user.otp_token != '') {

          axios({
            method: 'post',
            url: this.$api + 'forget/change',
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            },
            data: this.user
          })
          .then(res => {
            if (res !== undefined) {
              if (!res.data.status) {
                this.$fire({
                  type: 'error',
                  title: res.data.message
                });
              } else {
                this.$fire({
                  type: 'success',
                  title: res.data.message
                });

                this.$router.push({ path: '/login' });
              }

            } else {
              this.$fire({
                type: 'error',
                title: 'Invalid account'
              });
            }
          })
          .catch(error => {
            let err = Object.values(error.response.data.errors);
            let htmlerr = "";
            err.forEach((err) => {
              console.log(err);
              htmlerr = htmlerr + "<div>"+ err +"</div>";
            });

            this.$fire({
              type: 'error',
              title: error.response.data.message,
              html: htmlerr,
              timerProgressBar: true
            });
          });

        } else {
          axios({
            method: 'post',
            url: this.$api + 'forget/verify',
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
              'phone': this.user.phone
            }
          })
          .then(res => {
            if (res !== undefined) {
              if (!res.data.status) {
                this.$fire({
                  type: 'error',
                  title: res.data.message
                });
              } else {
                this.user.otp_token = res.data.otp_token;
                this.validAccount = true;
              }

            } else {
              this.$fire({
                type: 'error',
                title: 'Invalid account'
              });
            }
          })
          .catch(error => {
            let err = Object.values(error.response.data.errors);
            let htmlerr = "";
            err.forEach((err) => {
              console.log(err);
              htmlerr = htmlerr + "<div>"+ err +"</div>";
            });

            this.$fire({
              type: 'error',
              title: error.response.data.message,
              html: htmlerr,
              timerProgressBar: true
            });
          });
        }
      }
    }
  }
</script>