import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Forget from '../views/forget.vue'
import AgentRegister from '../views/agent/AgentRegister.vue'
import Terms from '../views/terms.vue'

// auth
import Games from '../views/Games.vue'
import PlayGame from '../views/PlayGame.vue'
import MyBet from '../views/MyBet.vue'
import LottoResultHistory from '../views/LottoResultHistory.vue'
import Transactions from '../views/Transactions.vue'
import Balance from '../views/Balance.vue'
import Profile from '../views/Profile.vue'
import Account from '../views/Accounts.vue'
import Dashboard from '../views/Dashboard.vue'
import MyBetById from '../views/MyBetById.vue'
import Inbox from '../views/inbox.vue'
import Withdraw from '../views/Withdraw.vue'

// agent
import Agent from '../views/agent/app.vue'
import Agent_dashboard from '../views/agent/dashboard.vue'
import Commissions from '../views/agent/Commissions.vue'
import SubAgent from '../views/agent/subagent.vue'
import subAgentsProfile from '../views/agent/subAgentsProfile.vue'
// agent
import Agents from '../views/agent/agents.vue'
import Bettors from '../views/agent/bettors.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/forget',
    name: 'forget',
    component: Forget
  },
  {
    path: '/reg/:types/:agent/invites',
    name: 'agentRegister',
    component: AgentRegister
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/games',
    component: Games
  },
  {
    path: '/withdraw',
    component: Withdraw
  },
  {
    path: '/inbox',
    component: Inbox
  },
  {
    path: '/game/:type',
    component: PlayGame
  },
  {
    path: '/my-bet',
    component: MyBet
  },
  {
    path: '/bet/:bet_id',
    component: MyBetById
  },
  {
    path: '/lotto-result',
    component: LottoResultHistory
  },
  {
    path: '/transactions',
    component: Transactions
  },
  {
    path: '/balance',
    component: Balance
  },
  {
    path: '/account',
    component: Account
  },
  {
    path: '/profile',
    component: Profile
  },
  // agent
  {
    path: '/agent',
    name: 'agent',
    component: Agent,
    children:[
      {
        path: 'dashboard',
        component: Agent_dashboard
      },
      {
        path: 'commissions',
        component: Commissions
      },
      {
        path: 'subagent',
        component: SubAgent
      },
      {
        path: 'bettors',
        component: Bettors

      },
      {
        path: 'agents',
        component: Agents
      },
      {
        path: 'subagent/profile/:subagent_id',
        component: subAgentsProfile
      }
    ]
  }
]

var router = new VueRouter({
  mode: 'history', // hash/history
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // const isAuthenticated = !!getToken(); // Check if the user is authenticated (replace with your own implementation)
  axios({
    url: Vue.prototype.$api + 'check-token',
    method: 'get',
    withCredentials: false,
  })
  .then(res => {
    
    if (res != undefined) {
      localStorage.setItem("user", JSON.stringify(res.data));
    } else {
      if (to.name != "home" && to.name != "register" && to.name != "agentRegister" && to.name != "terms" && to.name != "otp" && to.name != "forget") {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    }
  })
  .catch(error => {
      localStorage.removeItem("user");
    // if (error.response.status === 401) {
    // }
  });
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!requiresAuth) {
  //     next('/login');
  //   }
  // }

  next();

});

export default router
