<template>
  <div id="app">
    <div>
      <div class="noprint userProfile mb-2" v-if="isAuth">
        <div class="container">
          <div class="row">
            <div class="col p-0">
              <div class="row">
                <div class="col">
                  <img src="@/assets/images/profilepic.png" class="w-100">
                </div>
                <div class="col-8 userTopInfo">
                  <div class="">{{ user.phone }}</div>
                  <button v-if="user.role == 'user'"
                    class="btn btn-primary btn-sm cashIn rounded-pill"
                    data-bs-toggle="modal"
                    data-bs-target="#cashInModals"
                    v-on:click="checkCashin()"
                    >
                    <i class="fa fa-plus"></i> Cash in
                  </button>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="col-12 text-end">
                <div class="mb-2">
                  <strong>Cash Balance</strong>
                </div>
                <h1 class="balanceText">₱ <span class="">{{ user.balance.toLocaleString() }}</span></h1>
              </div>

              <div v-if="user.role != 'user'" class="text-center">
                <strong>Commissions</strong>
                <ul class="commissionList">
                  <li>
                    <strong>{{ user.commissions.LAST2 }}%</strong>
                    <div>Last 2</div>
                  </li>
                  <li class="border-end border-start middle">
                    <strong>{{ user.commissions.SWERTRES }}%</strong>
                    <div>Swertres</div>
                  </li>
                  <li>
                    <strong>{{ user.commissions.JUETENG }}%</strong>
                    <div>Jueteng</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <router-view />
      </div>
    </div>
    <div class="iconMenu noprint" v-if="isAuth">
      <ul class="footer-menu" v-if="user.role == 'user'">
        <li>
          <router-link to="/dashboard">
            <i class="fa-solid fa-scroll"></i>
            <div>Results</div>
          </router-link>
        </li>
        <li>
          <router-link to="/inbox">
            <i class="fa-solid fa-envelope"></i>
            <div>Inbox</div>
          </router-link>
        </li>
        <li>
          <router-link to="/games">
            <img src="@/assets/images/gameButton.png" class="footer-pcso">
          </router-link>
        </li>
        <li>
          <router-link to="/my-bet">
            <i class="fa-solid fa-table-list"></i>
            <div>Transaction</div>
          </router-link>
        </li>
        <li>
          <router-link to="/account">
            <i class="fa-solid fa-circle-user"></i>
            <div>Account</div>
          </router-link>
        </li>
      </ul>

      <ul class="footer-menu" v-else>
        <li>
          <router-link to="/agent/dashboard">
            <i class="fa-solid fa-house-chimney"></i>
            <div>Dashboard</div>
          </router-link>
        </li>

        <li v-if="user.role == 'master'">
          <router-link to="/agent/subagent">
            <i class="fa-solid fa-users-viewfinder"></i>
            <div>Sub Agents</div>
          </router-link>
        </li>
        <li v-if="user.role == 'master' || user.role == 'sub'">
          <router-link to="/agent/agents">
            <i class="fa-solid fa-user-secret"></i>
            <div>Agents</div>
          </router-link>
        </li>

        <li>
          <router-link to="/agent/bettors">
            <i class="fa-solid fa-users"></i>
            <div>Bettors</div>
          </router-link>
        </li>
        <li>
          <router-link to="/agent/commissions">
            <i class="fa-solid fa-table-list"></i>
            <div>Commissions</div>
          </router-link>
        </li>
        <li>
          <router-link to="/account">
            <i class="fa-solid fa-circle-user"></i>
            <div>Account</div>
          </router-link>
        </li>
      </ul>
    </div>  


    <!-- cash in modals -->
    <div class="modal" id="cashInModals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-4" id="exampleModalLabel">CASH IN</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6 text-center">
                <div v-if="isCashin.gcash">
                  <button v-on:click="cashIn.gateway = 'gcash'" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#cashInAmount">
                    <img src="@/assets/images/gcash.webp" class="img-fluid" />
                  </button>
                </div>
                <div class="cashMaintenance" v-else>
                  <img src="@/assets/images/gcash.webp" class="img-fluid" />
                  <h3>Under Maintenance</h3>
                </div>

              </div>
              <div class="col-6 text-center">
                <div v-if="isCashin.paymaya">
                  <button v-on:click="cashIn.gateway = 'paymaya'" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#cashInAmount">
                    <img src="@/assets/images/paymaya.webp" class="img-fluid" />
                  </button>
                </div>
                <div class="cashMaintenance" v-else>
                  <img src="@/assets/images/paymaya.webp" class="img-fluid" />
                  <h3>Under Maintenance</h3>
                </div>
              </div>
            </div> 
            <div class="row justify-content-center">
              <div class="col-6 text-center">
                <div v-if="isCashin.gcashqr">
                  <button v-on:click="cashIn.gateway = 'gcash_qr'" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#cashInAmount">
                    <img src="@/assets/images/gcashqr.webp" class="img-fluid" />
                  </button>
                </div>
                <div class="cashMaintenance" v-else>
                  <img src="@/assets/images/gcashqr.webp" class="img-fluid" />
                  <h3>Under Maintenance</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- set amount -->
    <div class="modal fade" id="cashInAmount" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <form @submit="submitCheckout">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Please enter amount</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input type="text" class="form-control" v-model="cashIn.amount">
              <button class="w-100 btn btn-warning mt-3">Cash in</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div id="reqLoading">
      <img src="@/assets/images/loading.svg">
    </div>
  </div>
</template>
<script>
  import mainMenu from '@/components/mainMenu';
  import axios from 'axios';
  export default {
    components: {
      mainMenu
    },
    watch:{
        $route (to, from){
          const me = this;
          setTimeout(function(){
            me.getAuth();
          },1000);
        }
    },
    // beforeRouteUpdate(to, from, next) {
    //   console.log('1');
    //   const me = this;
    //   setTimeout(function(){
    //     me.getAuth();
    //   },1000);
    //   next();
    // },
    data() {
      return {
        isAuth: false,
        isCashin: {
          gcash: 0,
          gcashqr: 0,
          paymaya: 0
        },
        user: {
          "id": null,
          "name": null,
          "email":null,
          "balance":0,
          "role": "",
          "commissions": {
            "LAST2" : 0,
            "SWERTRES" : 0,
            "JUETENG" : 0,
          }
        },
        plan: 'free',
        cashIn: {
          gateway: '',
          amount: 100
        }
      }
    },

    mounted() {
      this.getAuth();
    },

    methods: {
      getAuth() {
        if (localStorage.getItem("user")) {
          this.isAuth = true;
          this.user = JSON.parse(localStorage.getItem("user"));
        }
      },

      checkCashin() {
        axios({
              method: 'get',
              url: this.$api + 'gateway/cashin',
              withCredentials: false,
              headers: {
                  'Content-Type': 'application/json'
              }
            })
            .then(res => {
              this.isCashin = res.data.cashin;
            });
      },

      submitCheckout(e) {
        e.preventDefault();
        axios({
          method: 'post',
          url: this.$api + 'gateway/deposit',
          withCredentials: false,
          headers: {
              'Content-Type': 'application/json'
          },
          data: this.cashIn
        })
        .then(res => {
          if (res.data.success) {
            window.location.href = res.data.redirect_url;
          } else {
            this.$fire({
              type: 'error',
              title: 'ERROR',
              text: res.data.message
            })
          }
        }).catch(error => {
          let err = Object.values(error.response.data.errors);
          let htmlerr = "";
          err.forEach((err) => {
            htmlerr = htmlerr + "<div>"+ err +"</div>";
          });

          this.$fire({
                  type: 'error',
                  title: error.response.data.message,
                  html: htmlerr,
                  timerProgressBar: true,
                  timer: 2000
                });
        });;
      }
    }
  }
</script>