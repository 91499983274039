<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="nav nav-underline nav-justified">
					  <li class="nav-item">
					    <router-link class="nav-link" aria-current="page" to="/my-bet">My Bet</router-link>
					  </li>
					  <li class="nav-item">
					    <router-link class="nav-link active" to="/transactions">Transactions</router-link>
					  </li>
					</ul>
				</div>
				<div class="col-12 mt-3">
					<h1>Transaction History</h1>
					<div v-if="Object.keys(results).length > 0">
						<div>
							<div v-for="res in results.trans" :key="res.id" class="mb-3">
								<div class="card" v-bind:class="{'text-bg-success': (res.trans_type == 'WIN')}">
									<div class="card-header fw-bold">
										<div class="row">
											<div class="col-5">
												{{ res.trans_type }}
											</div>
											<div class="col text-end">
												{{ res.created_at }}
											</div>
										</div>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-6">
												{{ res.note }}
											</div>
											<div class="col text-end">
												<h3>
													<i class="fa fa-peso-sign"></i> {{ res.amount }}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<nav aria-label="Page navigation">
							<Paginate
							  :page-count="results.totalPage"
							  :click-handler="TriggerPaginate"
							  prev-text="Prev"
							  next-text="Next"
							  container-class="pagination"
							  page-class="page-item"
							  page-link-class="page-link"
							  prev-class="page-item"
							  prev-link-class="page-link"
							  next-class="page-item"
							  next-link-class="page-link"
							  >
							</Paginate>
						</nav>
					</div>
					<div v-else>
						No transaction found
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	import Paginate from 'vuejs-paginate'
	export default {
		components: {
			Paginate
		},
		data() {
			return {
				results: {}
			}
		},
		mounted() {
			this.init(1);
		},
		methods: {
			TriggerPaginate(page){
				this.init(page);
			},

			init(page) {
				const me = this;
				axios({
					method: 'get',
					withCredentials: false,
					url: this.$api + 'transactions?page=' + page
				}).then(res => {
					if (res !== undefined) {
						me.results = res.data;
					}
				});
			}
		}
	}
</script>