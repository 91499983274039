<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<div>
						<router-link to="/profile" class="btn btn-secondary border-0 rounded-5 w-50 text-dark fw-bold">Profile setting</router-link>
					</div>
					<div class="mt-3">
						<a href="https://www.facebook.com/PisoLotteryOnline" target="_blank" class="btn btn-secondary border-0 rounded-5 w-50 text-dark fw-bold">Contact support</a>
					</div>
					<div class="mt-3">
						<button class="btn btn-danger border-0 rounded-5 ps-4 pe-4 w-50 fw-bold text-dark" v-on:click="logout">Logout</button>
					</div>
				</div>
				<div class="col-12 text-center mt-4">
					<div class="card game-list text-bg-warning radius-top-left radius-bottom-right">
						<div class="p-3">
							<div class="p-2">
								<h3 class="fw-bold m-0">Cash Balance</h3>
								<h1 class="fw-bold m-0">
									<i class="fa fa-peso-sign"></i> {{ user.balance.toLocaleString() }}
								</h1>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 text-center mt-4">
					<button v-if="user.role == 'user'" class="btn btn-primary cashIn mb-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#cashInModals">
	                  <i class="fa fa-plus"></i> Cash in
	                </button>
	                <router-link to="/withdraw" class="btn btn-primary cashIn mb-3 rounded-pill ms-3">
	                  <i class="fa fa-minus"></i> Cash out
	                </router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				user: JSON.parse(localStorage.getItem("user"))
			}
		},
		methods: {
			logout() {
				localStorage.removeItem("user");
				localStorage.removeItem("authToken");
				this.isAuth = false;
				window.location.href = "/";
			}
		}
	}
</script>