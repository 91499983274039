<template>
	<div class="border border-1 p-3">
		<h3>Bettors</h3>
		<div class="table-responsive">
			<table class="table table-striped">
				<thead>
					<tr>
						<th>Number</th>
						<th>Balance</th>
					</tr>
				</thead>
				<tbody v-if="commission.length !== 0">
					<tr v-for="c in commission" :key="c.id">
						<td>{{ c.phone }}</td>
						<td><i class="fa fa-peso-sign"></i> {{ c.balance }}</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<th colspan="2">No bettors found</th>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				commission: {}
			}
		},
		mounted() {
			const me = this;
			axios({
				method: 'get',
				withCredentials: false,
				url: this.$api + 'agent/bettors'
			}).then(res => {
				if (res !== undefined) {
					this.commission = res.data;
				}
			});
		}
	}
</script>